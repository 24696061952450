<template lang="">
  <div>
    <v-select
      :value="packageConfigSelect"
      :options="packageOptions"
      label="id"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder ?? $t('placeholderSelect')"
      :reduce="val => val"
      @input="(val) => $emit('update:packageConfigSelect', val)"
    >
      <!-- :loading="loadingAgency" -->
      <!-- @open="getAgencyList" -->
      <template #option="data">
        <span
          class="d-block font-weight-bold text-truncate text-uppercase"
        >
          <span class="font-weight-bolder">{{ data.name }}</span> <small>({{ formatCurrency(data.price) }})</small>
        </span>
      </template>

      <template #selected-option="data">
        <span
          class="d-block font-weight-bold text-truncate text-uppercase"
        >
          <span class="text-info">{{ data.name }}</span> <small>({{ formatCurrency(data.price) }})</small>
        </span>
      </template>
      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import store from '@/store'
import retailPackageStore from '@/views/retail-package/retailPackageStore'

import { formatCurrency } from '@core/utils/filter'

export default {
  components: {
    vSelect: () => import('vue-select'),
  },
  props: {
    packageConfigSelect: {
      type: [Object, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    if (!store.hasModule('app-package-config')) {
      store.registerModule('app-package-config', retailPackageStore)
    }

    const packageOptions = computed(() => {
      const packageConfigs = store.getters['app-package-config/getPackageConfigs']
      return packageConfigs.filter(packageConfig => packageConfig.isGift)
    })

    return {
      packageOptions, formatCurrency,
    }
  },
}
</script>
<style lang="">

</style>
